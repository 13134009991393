import React from "react";

const AlumDisplay = ({ name, title, description, picture }) => {
  return (
    <div className="alum-area">
      <div className="alum-picture-container">
        <img className="alum-picture" src={picture} alt={name} />
      </div>

      <div className="alum-text">
        <h2 className="alum-name">{name}</h2>
        <h5 className="alum-title">{title}</h5>
        <p className="alum-description">{description}</p>
      </div>
    </div>
  );
};

export default AlumDisplay;
