import tina from "../Images/tina.png";
import nelson from "../Images/nelson.jpg";
import sps from "../Images/sps.png";
import ps from "../Images/PRIME Space.jpeg";
import pvd from "../Images/DowntownProvidence.png";
import main_campus from "../Images/MainCampus.png";
import hamzah from "../Images/HamzahAnsari.png";
import bdw from "../Images/BrownDesignWorkshop.png";
import Action_data from "../Data/Explore_data.json";
import placeholder from "../Images/placeholder.png";
import marco from "../Images/marco.png";
import pat from "../Images/pat.png";
import shaelynn from "../Images/shae-lynn.png";

class ActivityClass {
  constructor(key, picture, title, subtitle, description, isUnlock, actions) {
    this.key = key;
    this.picture = picture;
    this.title = title;
    this.subtitle = subtitle;
    this.description = description;
    this.isUnlock = JSON.parse(window.localStorage.getItem(title));
    this.actions = actions;
  }

  setLocalStorage(name, num) {
    window.localStorage.setItem(
      name,
      parseInt(JSON.parse(window.localStorage.getItem(name))) + num
    );
  }

  incrementCounter() {
    this.setLocalStorage(this.title + "_counter", 1);
  }

  addAction(action) {
    if (action.unlockObj === "None" && action.chance === 1) {
      action.isAvailable = true;
    }
    if (action.isRepeatable === "FALSE") {
      action.isRepeatable = false;
    } else action.isRepeatable = true;
    this.actions.push(
      new Action(
        action.Key,
        action.ActionText,
        action.HoverTxt,
        action.resultTxt,
        action.chance,
        action.isAvailable,
        action.unlockObj,
        action.unlockNum,
        action.EffectType,
        action.Effect,
        action.EffectNum,
        action.isRepeatable
      )
    );
  }
}

/*
  Action class that facilitates the modulation of specific actions 
  */
class Action {
  constructor(
    key,
    text,
    hoverTxt,
    resultTxt,
    chance,
    isAvailable,
    unlockCond,
    unlockCondCrit,
    effectType,
    effect,
    effectNum,
    isRepeatable
  ) {
    this.key = key;
    this.text = text;
    this.resultTxt = resultTxt;
    this.hoverTxt = hoverTxt;
    this.chance = chance;
    this.isAvailable = isAvailable;
    this.unlockCond = unlockCond;
    this.unlockCondCrit = unlockCondCrit;
    this.effectType = effectType;
    this.effect = effect;
    this.effectNum = effectNum;
    this.isRepeatable = isRepeatable;
    if (this.isFinished) this.isFinished = true;
    else this.isFinished = false;
  }

  setLocalStorage(name, num) {
    window.localStorage.setItem(
      name,
      parseInt(JSON.parse(window.localStorage.getItem(name))) + num
    );
  }

  check(type, num) {
    if (type === "EqStartup") {
      if (parseInt(window.localStorage.getItem("Startup")) === num) {
        return true;
      } else {
        return false;
      }
    }
    if (type === "EqJob") {
      if (parseInt(window.localStorage.getItem("Job")) === num) return true;
      else return false;
    }
    if (parseInt(window.localStorage.getItem(type)) >= num) {
      return true;
    } else {
      return false;
    }
  }

  shouldRender() {
    if (this.isFinished || window.localStorage.getItem(this.text)) {
      return false;
    }

    if (this.effect === "Startup") {
      if (
        parseInt(window.localStorage.getItem("Startup")) ===
        this.effectNum - 1
      )
        return true;
      else return false;
    }

    if (this.effect === "Job") {
      if (
        parseInt(window.localStorage.getItem("Job")) === this.effectNum - 1 &&
        this.check(this.unlockCond, this.unlockCondCrit)
      )
        return true;
      else return false;
    }
    if (this.isAvailable) return true;
    else if (this.check(this.unlockCond, this.unlockCondCrit)) {
      if (Math.random() <= this.chance) {
        return true;
      } else return false;
    }
    return false;
  }
}

let activityList = [
  new ActivityClass(
    0,
    tina,
    "Tina Garfinkel",
    "Assistant Director of PRIME",
    "Tina handles the administrative tasks at PRIME. You can ask her about virtually anything and she will always point you to the right direction.",
    true,
    []
  ),

  new ActivityClass(
    1,
    shaelynn,
    "Shae-Lynn Cohen",
    "Program Coordinator of PRIME",
    "Shae-Lynn helps plan different events at PRIME, and she is a great resource for any questions regarding your life at PRIME",
    false,
    []
  ),

  new ActivityClass(
    2,
    pat,
    "Patrick McHugh",
    `Program Director of PRIME`,
    "Professor Pat is the ultimate resource for academics. He even has two patents in call center automation!",
    false,
    []
  ),

  new ActivityClass(
    3,
    sps,
    "School of Professional Studies",
    "Where jobs become a reality",
    "The School of Professional Studies has world class career service and networking events that helps you land your dream job.",
    false,
    []
  ),

  new ActivityClass(
    4,
    nelson,
    "Nelson Center of Entrepreneurship",
    "The breeding ground of world-changing ideas",
    "The School of Professional Studies has world class career service and networking events that helps you land your dream job.",
    false,
    []
  ),

  new ActivityClass(
    5,
    ps,
    "PRIME Space",
    "Home of PRIME students",
    "PRIME Space is the hub that is exclusive to PRIME students. Students often set up workstations here to study, practice interviews, and formulate start-up ideas.",
    false,
    []
  ),

  new ActivityClass(
    6,
    pvd,
    "Downtown Providence",
    "Small city with a big heart",
    "Providence is a vibrant city with a lot of activities!",
    false,
    [
      //   new Action(0,'Watch Watch Waterfire', 'Waterfire is a local attraction on the Woonasquatucket River where fire lights up on the sculptures in the water.',`It's a calm night. You stood by the Woonasquatucket River with your friends. Like your journey ahead, fire lights up in the water, throwing comforting warmth and a shade of cozy orange on your face, as if reminding you it's only the beginning. You gained a point in all categories.`,1,true,'Entrepreneurship',-10,'score','All',1,true),
      //   new Action(1,'Hangout at River House', 'Many of your friends live at this riverfront off-campus apartments. Go hangout with them!','You gathered around the common room on the first floor and played an intense game of UNO. You lost the game only by a slight margin because you forgot to say "UNO". You gained 2 social points.',1,true,'Entrepreneurship',-10,'score','Soc',2,true),
      //   new Action(2,'Use the CiC space', 'PRIME students can use the RiHub incubator space, which includes mentorship services, conference room access, and unlimited access of the snack bar. ','You are now a member at the CiC Providence located at 225 Dyer Street. From there, you have additional resources to help your entrepreneurship venture!',1,false,'Entrepreneurship',15,'score','Ent',5,false),
    ]
  ),

  new ActivityClass(
    7,
    main_campus,
    "Main Campus",
    "The Main Brown Campus",
    "The Main Brown Campus offers a lot of things to do, ranging from social events to football games.",
    false,
    []
  ),

  new ActivityClass(
    8,
    marco,
    "Marco Ma",
    `Teaching Assistant for PRIME`,
    "Marco is your PRIME big brother who will always have your back.",
    false,
    []
  ),

  new ActivityClass(
    9,
    bdw,
    "Brown Design Workshop",
    `Where you can build anything`,
    "The Brown Design Workshop is a student-run makerspace which aims to make the practices of design and creation more collaborative, open, flexible and accessible. ",
    false,
    []
  ),

  new ActivityClass(
    10,
    hamzah,
    "Hamzah Ansari",
    "Lecturer and your sherpa for start-up ideas",
    "Hamzah used to be a PRIME student from the class of 2009!",
    false,
    []
  ),

  new ActivityClass(
    11,
    "../Images/Thayer.png",
    "？？？",
    ``,
    "Thayer Street is where PRIME students go grab coffee, food, and possibly network with Professors.",
    false,
    []
  ),
];

Action_data.map((action) => activityList[action.CategoryID].addAction(action));

export { activityList };
