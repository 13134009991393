import React from "react";
import { Button } from "@mui/material";
import "../App.css";
import "../Styling/Buttons.css";

const Home = ({ setScreen, pageState }) => {
  return (
    <div className="homePage">
      <h1 className="title">Life @ PRIME</h1>
      <h2 className="main-subtitle">
        An immersive simulation of the PRIME program at Brown University
      </h2>
      <button
        className="button-59"
        onClick={() => {
          console.log(`page state is ${pageState}`);

          if (JSON.parse(window.localStorage.getItem("pageState")) === -1)
            setScreen("Login");
          else if (JSON.parse(window.localStorage.getItem("pageState")) === 0)
            setScreen("Question");
          else if (JSON.parse(window.localStorage.getItem("pageState")) === 1)
            setScreen("Activities");
          else if (JSON.parse(window.localStorage.getItem("pageState")) === 2)
            setScreen("Result");
        }}
      >
        Start your journey &gt;&gt;
      </button>
    </div>
  );
};

export default Home;
