import React from "react";
import ActivityButton from "./ActivityButton";

const Activity = ({ activity, setResponse, setState }) => {
  const handleResponse = (action) => {
    setResponse(action.key);
    if (!action.isRepeatable) {
      action.isFinished = true;
      window.localStorage.setItem(action.text, false);
    }
    setState("Result");
  };

  // let buttons = [];
  // for(let i=0; i<activity.actions.length;i++){
  //   let currAction = activity.actions[i]
  //   if(currAction.shouldRender()){
  //   let button = <Button sx={{m: 2, p: 1}} variant="contained" onClick={()=>{handleResponse(i)}}>{currAction.text}</Button>
  //   buttons.push(button);
  //   }
  // }

  return (
    <div className="activity-page">
      <button
        className="button-back"
        onClick={() => {
          setState("ActivitiesHome");
        }}
      >
        Back
      </button>
      <div className="activity-header">
        <div className="activity-img-area">
          <img
            className="activity-img"
            src={activity.picture}
            alt={activity.title}
          />
        </div>
        <div className="activity-text">
          <h2 className="activity-title">{activity.title}</h2>
          <h3 className="activity-subtitle">{activity.subtitle}</h3>
          <p className="activity-description">{activity.description}</p>
        </div>
      </div>
      <div className="activity-button-area">
        {activity.actions.map((action) =>
          action.shouldRender() ? (
            // <div className = "activity-button">
            // <button className='button-57' key={action.key}

            // onClick={()=>{handleResponse(action.key)}}>{action.text}</button>
            <div className="activity-button-area" key={action.key}>
              <ActivityButton action={action} handleResponse={handleResponse} />
            </div>
          ) : (
            <div className="empty-button-area" key={action.key}></div>
          )
        )}
      </div>
      <div className="activity-buffer"></div>
    </div>
  );
};

export default Activity;
