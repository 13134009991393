import React, { useState } from "react";
import "../Styling/Buttons.css";

const QuestionButton = ({
  question,
  position,
  buttonText,
  hoverText,
  updateIndex,
  setResponse,
  toResult,
  isJob,
  setJobName,
  setScore,
}) => {
  const [hover, setHover] = useState("");
  return (
    <>
      <button
        className="button-57"
        onMouseEnter={() => {
          setHover(hoverText);
        }}
        onMouseLeave={() => {
          setHover("");
        }}
        onClick={() => {
          if (position === 0) {
            if (isJob) {
              setJobName("JP Morgan Chase");
              setScore("Job", 0);
              window.localStorage.setItem("JobName", "JP Morgan Chase");
            }
            updateIndex(question.btn1next);
          } else if (position === 1) {
            if (isJob) {
              setJobName("Bain & Company");
              setScore("Job", 0);
              window.localStorage.setItem("JobName", "Bain & Company");
            }
            updateIndex(question.btn1next);
          } else if (position === 2) {
            if (isJob) {
              setJobName("Microsoft");
              setScore("Job", 0);
              window.localStorage.setItem("JobName", "Microsoft");
            }
            updateIndex(question.btn1next);
          }
          setResponse(position);
          toResult();
        }}
      >
        {buttonText}
      </button>

      <p className="hover-text">{hover}</p>
    </>
  );
};

export default QuestionButton;
