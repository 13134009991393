import React from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const QuestionResult = ({
  question,
  response,
  setScreen,
  setScore,
  unlock,
  setLocalStorage,
  saveIndex,
  setPageState,
}) => {
  var resultTxt;

  const db = firebase.firestore();
  const username = window.localStorage.getItem("username");

  const evaluateEffect = (type, effect, num) => {
    if (type === "score") {
      setScore(effect, num);
    } else if (type === "unlock") {
      unlock(effect);
    } else if (type === "setCounter") {
      setLocalStorage(effect, num);
    }
  };

  const setResultTxt = () => {
    let dbkey = question.Topic;
    switch (response) {
      case 0:
        resultTxt = question.result1Txt;
        db.collection("Users")
          .doc(username)
          .update({
            [dbkey]: question.btn1,
          });
        break;
      case 1:
        resultTxt = question.result2Txt;
        db.collection("Users")
          .doc(username)
          .update({
            [dbkey]: question.btn2,
          });
        break;
      case 2:
        resultTxt = question.result3Txt;
        db.collection("Users")
          .doc(username)
          .update({
            [dbkey]: question.btn3,
          });
        break;
      default:
        return null;
    }
  };

  const updatePoints = () => {
    switch (response) {
      case 0:
        evaluateEffect(
          question.effect1Type,
          question.effect1,
          question.effect1Num
        );
        break;
      case 1:
        evaluateEffect(
          question.effect2Type,
          question.effect2,
          question.effect2Num
        );
        break;
      case 2:
        evaluateEffect(
          question.effect3Type,
          question.effect3,
          question.effect3Num
        );
        break;
      default:
        return null;
    }
  };
  setResultTxt();
  return (
    <div className="result-area">
      <h2 className="result-text">{resultTxt}</h2>
      <div className="result-button">
        <button
          className="button-57"
          onClick={() => {
            updatePoints();
            saveIndex();
            if (question.isEnd === "TRUE") {
              window.localStorage.setItem("pageState", 2);
              setScreen("Result");
            } else {
              window.localStorage.setItem("pageState", 1);
              setScreen("Activities");
            }
          }}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default QuestionResult;
