import React, { useState } from "react";
import Card from "@mui/joy/Card";
import CardCover from "@mui/joy/CardCover";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

const ActivityCard = ({ activity, isUnlock, setState, setCurrActivity }) => {
  return (
    <div className={isUnlock ? "mui-card" : "disabled-mui-card"}>
      <Card
        sx={{ minHeight: "200px" }}
        onClick={() => {
          if (JSON.parse(window.localStorage.getItem(activity.title))) {
            setState("ActivityDetail");
            setCurrActivity(activity);
          }
        }}
      >
        <CardCover>
          <img src={activity.picture} loading="lazy" alt={activity.title} />
        </CardCover>
        {isUnlock ? (
          <CardCover
            sx={{
              background:
                "linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)",
            }}
          />
        ) : (
          <CardCover
            sx={{
              background: "#4E3629",
            }}
          />
        )}
        <CardContent sx={{ justifyContent: "flex-end" }}>
          {isUnlock ? (
            <></>
          ) : (
            <LockOutlinedIcon
              p={3}
              sx={{ color: "white", margin: "auto", fontSize: 80 }}
            />
          )}
          <Typography
            level="h2"
            fontSize="lg"
            fontFamily="Roboto"
            textAlign="left"
            textColor="#fff"
            mb={1}
          >
            {activity.title}
          </Typography>
          <Typography textColor="neutral.300" textAlign="left">
            {activity.subtitle}

            {/* <a className='card-link' href='https://prime.brown.edu/people/tina-garfinkel' target='_blank'> */}
            {/* <OpenInNewIcon sx={{color: '#98A4AE', fontSize: 'medium'}} />
        </a> */}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default ActivityCard;
