import React from "react";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  Legend,
  RadialLinearScale,
  Filler,
  Tooltip,
} from "chart.js";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Radar } from "react-chartjs-2";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { useEffect } from "react";
import AlumDisplay from "./AlumDisplay";
import chinmay from "../Images/chinmay.png";
import firebase from "firebase/compat/app";
import leo from "../Images/leo.png";
import dollar from "../Images/dollar.png";
import marco from "../Images/marco.png";
import "firebase/compat/firestore";

ChartJS.register(
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  RadialLinearScale,
  Filler
);

const ResultPage = ({ setScreen, getScore, setPageState }) => {
  const chinmayDescription = `Chinmay Shrivastava is a 2022 alumnus from Brown University’s Master's in Innovation Management and Entrepreneurship (PRIME). He grew up in India and has been pursuing Mathematics and Science since high school. In 2021, he graduated with a Bachelor's in Mechanical Engineering from the Indian Institute of Technology Roorkee, Roorkee, India. He has always been interested in entrepreneurship and has been pursuing entrepreneurial projects since his undergraduate days. Currently, he is working on an educational technology to individualize and democratize learning for everyone, everywhere in the world.`;
  const dollarDescription = `Dollar is a PRIME alum from the class of 2023. He is originally from China, but came to the US since he was 14. He thinks of himself as a creator and loves to make things that solves real world problems! This react app is built by his team. When he is not making cool things, he is probably playing basketball or the piano.`;
  const leoDescription = `Leo is a PRIME alum from the class of 2023. He did his undergraduate degree in Philosophy and Economics from UW. Energetic and outgoing, he can always light up the room. He the co-founder of Campaverse, and he hopes to improve the student experience in colleges through this startup venture.`;
  const marcoDescription = `Marco grew up in Beijing and moved to the United States when he was 14. He received his Bachelor of Science in Business Administration from Boston University's Questrom School of Business with a double concentration in finance and strategy in 2018. During his undergraduate studies, he interned with venture capital firms specializing in operational due diligence and go-to-market strategy. Following his graduation in 2018, he spent 15 months leading a transformation project for a consumer products e-commerce company in New York City before joining Brown University to continue his studies. Marco Ma is the research assistant of the program, with a focus on corporate leaders' decisions in technology investments and transformational projects. His recent work supporting PRIME faculty has just been nominated for best case submission awards at conferences.`;
  const db = firebase.firestore();
  const username = window.localStorage.getItem("username");
  let scoreList = [
    getScore("Ent"),
    getScore("Car"),
    getScore("Cre"),
    getScore("Ana"),
    getScore("Soc"),
  ];
  let maxAttribute = scoreList.indexOf(Math.max(...scoreList));
  let relevantAlum = null;

  if (maxAttribute === 0) {
    relevantAlum = (
      <AlumDisplay
        name="Chinmay Shrivastava '22"
        title="Founder of Unfinite"
        description={chinmayDescription}
        picture={chinmay}
      />
    );
  } else if (maxAttribute === 2) {
    relevantAlum = (
      <AlumDisplay
        name="Dollar Zhu '23"
        title="Co-Founder of Campaverse"
        description={dollarDescription}
        picture={dollar}
      />
    );
  } else if (maxAttribute === 4) {
    relevantAlum = (
      <AlumDisplay
        name="Leo Yang '23"
        title="Co-Founder of Campaverse"
        description={leoDescription}
        picture={leo}
      />
    );
  } else {
    relevantAlum = (
      <AlumDisplay
        name="Marco Ma '20"
        title="Founder of Relastics Co."
        description={marcoDescription}
        picture={marco}
      />
    );
  }
  const addScore = () => {
    db.collection("Users")
      .doc(username)
      .update({
        Entrepreneurship: getScore("Ent"),
        Creativity: getScore("Cre"),
        Career: getScore("Car"),
        Analytical: getScore("Ana"),
        Social: getScore("Soc"),
      })
      .then(function () {
        console.log("name successfully written!");
      })
      .catch(function (error) {
        console.error("Error writing name: ", error);
      });
  };

  useEffect(() => {
    addScore();
  }, []);

  const options = {
    elements: {
      line: {
        borderWidth: 1,
        borderColor: "white",
      },
    },
    plugins: {
      legend: {
        display: true,
        labels: {
          color: "white",
          padding: 25,
        },
      },
    },
    scales: {
      r: {
        angleLines: {
          display: true,
          color: "#B7B09C",
        },
        grid: {
          display: true,
          color: "#B7B09C",
        },
        pointLabels: {
          color: "white",
          font: {
            size: 22,
            family: "Helvetica Neue",
            weight: 300,
          },
        },
        ticks: {
          display: false,
        },
      },
    },
  };

  const data = {
    labels: [
      `Entrepreneurship: ${getScore("Ent")}`,
      `Career: ${getScore("Car")}`,
      `Creativity: ${getScore("Cre")}`,
      `Analytical: ${getScore("Ana")}`,
      `Social: ${getScore("Soc")}`,
    ],
    datasets: [
      {
        label: "Your Profile",
        data: [
          getScore("Ent"),
          getScore("Car"),
          getScore("Cre"),
          getScore("Ana"),
          getScore("Soc"),
        ],
        backgroundColor: "rgba(255, 199, 44, 0.6)",
        borderColor: "#B7B09C",
      },
      {
        label: "Average PRIME Profile",
        data: [25, 22, 27, 23, 29],
        backgroundColor: "rgba(0, 60, 113, 0.6)",
        borderColor: "#B7B09C",
      },
      {
        label: "Average PRIME Entrepreneur Profile",
        data: [34, 16, 21, 24, 35],
        backgroundColor: "rgba(89, 203, 232, 0.6)",
        borderColor: "#B7B09C",
      },
      {
        label: "Average PRIME Consultant Profile",
        data: [13, 34, 24, 28, 32],
        backgroundColor: "rgba(0, 179, 152, 0.6)",
        borderColor: "#B7B09C",
      },
    ],
  };

  return (
    <div className="result-page">
      <div className="result-banner" id="result-banner">
        <div className="result-display-child">
          <AnimationOnScroll
            initiallyVisible={true}
            scrollableParentSelector="#result-banner"
            animateIn="animate__fadeIn"
            animateOut="animate__fadeOut"
          >
            <h1 className="result-title">
              Congratulations! <br></br> You completed your journey at PRIME
              <br></br>
              <span className="scroll-hint">
                &lt; Scroll down to view your PRIME profile &gt;
              </span>
            </h1>
          </AnimationOnScroll>

          <AnimationOnScroll
            initiallyVisible={false}
            scrollableParentSelector="#result-banner"
            animateIn="animate__fadeInLeft"
            animateOut="animate__fadeOutBig"
          >
            <div className="resultRow">
              <div>
                <h2 className="result-header">Your PRIME Profile</h2>
                <p className="scroll-hint">
                  Click on the legend to hide/show different graphs
                </p>
              </div>
              <div className="result-graph">
                <Radar data={data} options={options} color="black"></Radar>
              </div>
            </div>
          </AnimationOnScroll>

          <AnimationOnScroll
            initiallyVisible={false}
            scrollableParentSelector="#result-banner"
            animateIn="animate__fadeIn"
            animateOut="animate__fadeOutBig"
          >
            <div className="alum-recommendation">
              <h2 className="result-header">PRIME Alums like you</h2>
              {relevantAlum}
            </div>
          </AnimationOnScroll>

          <AnimationOnScroll
            initiallyVisible={false}
            scrollableParentSelector="#result-banner"
            animateIn="animate__fadeIn"
            animateOut="animate__fadeOutBig"
          >
            <div className="final-quote">
              <h2 className="result-quote-text">
                “The best way to predict the future is to create it.” <br></br>{" "}
                <br></br>- Peter Drucker
              </h2>

              <button
                className="button-59"
                onClick={() => {
                  addScore();
                  window.localStorage.setItem("pageState", 0);
                  setScreen("Home");
                  window.localStorage.setItem("currIndex", 0);
                  window.localStorage.clear();
                  window.location.reload();
                }}
              >
                Start over
              </button>
            </div>
          </AnimationOnScroll>
        </div>
      </div>
    </div>
  );
};

export default ResultPage;
