import React, { useEffect } from "react";
import { useState } from "react";
import Activity from "./Activity";
import ActivityResult from "./ActivityResult";
import ActivityCard from "./ActivityCard";
import Grid from "@mui/material/Grid";
import Scoreboard from "./Scoreboard";
import { activityList } from "../components/ActivityData";

const ActivitiesHandler = ({
  setScreen,
  getScore,
  setScore,
  unlock,
  check,
  setLocalStorage,
  setPageState,
}) => {
  const [currActivity, setCurrActivity] = useState(null);
  const [state, setState] = useState("ActivitiesHome");
  const [response, setResponse] = useState(0);

  if (JSON.parse(window.localStorage.getItem("currIndex") >= 2)) {
    unlock("School of Professional Studies");
  }

  if (JSON.parse(window.localStorage.getItem("currIndex") >= 4)) {
    unlock("PRIME Space");
    unlock("Nelson Center of Entrepreneurship");
  }

  if (JSON.parse(window.localStorage.getItem("currIndex") >= 5)) {
    unlock("Main Campus");
    unlock("Downtown Providence");
    unlock("Marco Ma");
  }
  if (JSON.parse(window.localStorage.getItem("currIndex") >= 10)) {
    unlock("Hamzah Ansari");
  }

  let unlockFirst;
  const sortList = () => {
    unlockFirst = activityList.sort((a, b) => b.isUnlock - a.isUnlock);
  };
  sortList();

  var currState = <h2> </h2>;
  const evalState = () => {
    switch (state) {
      case "ActivitiesHome":
        currState = (
          <div className="activities-home">
            <h1 className="exploration-title">
              Exploration{" "}
              <span className="activities-date">
                / {window.localStorage.getItem("time")}
              </span>
            </h1>
            <ul>
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 2, sm: 4, md: 6 }}
              >
                {unlockFirst.map((activity) => (
                  <Grid item xs={12} md={6} lg={4} key={activity.key}>
                    <ActivityCard
                      activity={activity}
                      isUnlock={JSON.parse(
                        window.localStorage.getItem(activity.title)
                      )}
                      setCurrActivity={setCurrActivity}
                      setState={setState}
                    />
                  </Grid>
                ))}
              </Grid>
            </ul>
          </div>
        );
        break;
      case "ActivityDetail":
        currState = (
          <Activity
            activity={currActivity}
            setResponse={setResponse}
            setState={setState}
          />
        );
        break;

      case "Result":
        currState = (
          <ActivityResult
            activity={currActivity}
            response={response}
            setScreen={setScreen}
            setScore={setScore}
            unlock={unlock}
            setLocalStorage={setLocalStorage}
            setPageState={setPageState}
          />
        );
        break;
      default:
        return null;
    }
  };

  evalState();

  return (
    <div className="main-grid">
      <Scoreboard getScore={getScore} />
      <div className="exploration-body">{currState}</div>
    </div>
  );
};

export default ActivitiesHandler;
