import "./App.css";
import { useState } from "react";
import MainContent from "./MainContent";
import Navbar from "./components/Navbar";
import "animate.css/animate.min.css";

function App() {
  //step 1: create/load library

  const scoreLibrary = {
    Career: 10,
    Entrepreneurship: 10,
    Analytical: 10,
    Creativity: 10,
    Social: 10,
    Startup: -1,
    Job: -1,
  };

  const activityLibrary = {
    "Tina Garfinkel": true,
    "School of Professional Studies": false,
    "Nelson Center of Entrepreneurship": false,
    "Downtown Providence": false,
    "Main Campus": false,
    "Shae-Lynn Cohen": true,
    "Patrick McHugh": true,
    "Marco Ma": false,
    "Brown Design Workshop": false,
  };

  const counterLibrary = {
    "Tina Garfinkel_counter": 0,
    "PRIME Space_counter": 0,
    "School of Professional Studies_counter": 0,
    "Nelson Center of Entrepreneurship_counter": 0,
    "Hamzah Ansari_counter": 0,
    "Main Campus_counter": 0,
    "Downtown Providence_counter": 0,
    "Shae-Lynn Cohen_counter": 0,
    "Patrick McHugh_counter": 0,
    "Marco Ma_counter": 0,
    "Brown Design Workshop_counter": 0,
  };

  const BadgeLibrary = {};

  //step 2: pass the library items into local storage
  if (JSON.parse(window.localStorage.getItem("Entrepreneurship") === null)) {
    for (const key in scoreLibrary) {
      window.localStorage.setItem(key, scoreLibrary[key]);
    }
    for (const key in activityLibrary) {
      window.localStorage.setItem(key, activityLibrary[key]);
    }
    for (const key in counterLibrary) {
      window.localStorage.setItem(key, counterLibrary[key]);
    }
    for (const key in BadgeLibrary) {
      window.localStorage.setItem(key, BadgeLibrary[key]);
    }
  }

  if (JSON.parse(window.localStorage.getItem("pageState") === null)) {
    window.localStorage.setItem("pageState", -1);
  }

  //const [score,setScore] = useState(scoreLibrary);
  const [entScore, setEntScore] = useState(
    JSON.parse(window.localStorage.getItem("Entrepreneurship"))
  );
  const [anaScore, setAnaScore] = useState(
    JSON.parse(window.localStorage.getItem("Analytical"))
  );
  const [carScore, setCarScore] = useState(
    JSON.parse(window.localStorage.getItem("Career"))
  );
  const [creScore, setCreScore] = useState(
    JSON.parse(window.localStorage.getItem("Creativity"))
  );
  const [socScore, setSocScore] = useState(
    JSON.parse(window.localStorage.getItem("Social"))
  );
  const [startupScore, setStartupScore] = useState(
    JSON.parse(window.localStorage.getItem("Startup"))
  );
  const [jobScore, setJobScore] = useState(
    JSON.parse(window.localStorage.getItem("Job"))
  );

  const [startupName, setStartupName] = useState("");
  const [jobName, setJobName] = useState("");

  const setScore = (type, num) => {
    switch (type) {
      case "Startup":
        setStartupScore(num);
        window.localStorage.setItem("Startup", num);
        break;
      case "Job":
        setJobScore(num);
        window.localStorage.setItem("Job", num);
        break;
      case "Ent":
        setEntScore(entScore + num);
        window.localStorage.setItem("Entrepreneurship", entScore + num);
        break;
      case "Ana":
        setAnaScore(anaScore + num);
        window.localStorage.setItem("Analytical", anaScore + num);
        break;
      case "Car":
        setCarScore(carScore + num);
        window.localStorage.setItem("Career", carScore + num);
        break;
      case "Cre":
        setCreScore(creScore + num);
        window.localStorage.setItem("Creativity", creScore + num);
        break;
      case "Soc":
        setSocScore(socScore + num);
        window.localStorage.setItem("Social", socScore + num);
        break;
      case "Ent,Ana":
        setScore("Ent", num);
        setScore("Ana", num);
        break;
      case "Ent,Cre":
        setScore("Ent", num);
        setScore("Cre", num);
        break;
      case "Ent,Soc":
        setScore("Ent", num);
        setScore("Soc", num);
        break;
      case "Ana,Car":
        setScore("Ana", num);
        setScore("Car", num);
        break;
      case "Ana,Cre":
        setScore("Ana", num);
        setScore("Cre", num);
        break;
      case "Ana,Soc":
        setScore("Ana", num);
        setScore("Soc", num);
        break;
      case "Cre,Soc":
        setScore("Cre", num);
        setScore("Soc", num);
        break;
      case "All":
        setScore("Ent", num);
        setScore("Ana", num);
        setScore("Car", num);
        setScore("Cre", num);
        setScore("Soc", num);
        break;
      default:
        return null;
    }
  };

  const getScore = (type) => {
    switch (type) {
      case "Ent":
        return entScore;
      case "Ana":
        return anaScore;
      case "Car":
        return carScore;
      case "Cre":
        return creScore;
      case "Soc":
        return socScore;
      case "Startup":
        return startupScore;
      case "Job":
        return jobScore;
      default:
        return null;
    }
  };

  const unlock = (name) => {
    window.localStorage.setItem(name, true);
  };

  const check = (type, num) => {
    if (JSON.parse(window.localStorage.getItem(type) >= num)) return true;
    else return false;
  };

  const setLocalStorage = (name, num) => {
    window.localStorage.setItem(
      name,
      parseInt(JSON.parse(window.localStorage.getItem(name))) + num
    );
  };

  return (
    //<ThemeProvider theme={THEME}>
    <div className="App">
      <Navbar />
      <MainContent
        getScore={getScore}
        setScore={setScore}
        unlock={unlock}
        check={check}
        setLocalStorage={setLocalStorage}
        setStartupName={setStartupName}
        setJobName={setJobName}
      />
    </div>
    //</ThemeProvider>
  );
}

export function setLocalStorage(name, num) {
  window.localStorage.setItem(
    name,
    parseInt(JSON.parse(window.localStorage.getItem(name))) + num
  );
}

export function check(type, num) {
  if (JSON.parse(window.localStorage.getItem(type) >= num)) return true;
  else return false;
}

export function unlock(name) {
  window.localStorage.setItem(name, true);
}

export default App;
