import { useState, React } from "react";
import "../Styling/Buttons.css";
import "../App.css";
import QuestionButton from "./QuestionButton";
import { TextField } from "@mui/material";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const Question = ({
  question,
  setResponse,
  setScore,
  toResult,
  setStartupName,
  setJobName,
}) => {
  const updateIndex = (target) => {
    //window.localStorage.setItem('next', target);
    window.localStorage.setItem("currIndex", target);
  };

  const db = firebase.firestore();
  const username = window.localStorage.getItem("username");
  const [input, setInput] = useState("");

  let questionArea;

  const change = (event) => {
    event.preventDefault();
    setInput(event.target.value);
  };

  const evaluateInput = () => {
    if (input === "") {
      setResponse(0);
      updateIndex(question.btn1next);
      toResult();
    } else {
      setResponse(1);
      updateIndex(question.btn2next);
      if (question.type === "course" && input.length >= 4) {
        db.collection("Users")
          .doc(username)
          .update({
            Course: input.slice(0, 4).toUpperCase(),
          })
          .then(function () {
            console.log("name successfully written!");
          })
          .catch(function (error) {
            console.error("Error writing name: ", error);
          });
      } else if (question.type === "startup") {
        db.collection("Users")
          .doc(username)
          .update({
            Startup: input,
          })
          .then(function () {
            console.log("name successfully written!");
          })
          .catch(function (error) {
            console.error("Error writing name: ", error);
          });
      } else if (question.type === "job") {
        db.collection("Users")
          .doc(username)
          .update({
            Career: input,
          })
          .then(function () {
            console.log("name successfully written!");
          })
          .catch(function (error) {
            console.error("Error writing name: ", error);
          });
      }
      toResult();
    }
  };

  const renderQuestion = () => {
    if (question.type === "course") {
      questionArea = (
        <div>
          <div className="question">
            <h3 className="question-time">{question.time}</h3>
            <h2 className="question-text">{question.questiontxt}</h2>
          </div>
          <div className="response-area">
            <a className="cab-link" href="http://cab.brown.edu" target="_blank">
              <button className="button-57"> View Course Catalog</button>
            </a>
            <div className="input-field">
              <TextField
                margin="normal"
                sx={{ width: "300px" }}
                id="standard-basic"
                placeholder="Leave blank if you're not sure"
                onChange={change}
                label="Course code"
                variant="standard"
              />
            </div>
            <button className="button-57" onClick={evaluateInput}>
              {" "}
              Submit
            </button>

            <div className="button-area"></div>
          </div>
        </div>
      );
    } else if (question.type === "blank") {
      questionArea = (
        <div>
          <div className="question">
            <h1 className="question-topic">{question.Topic}</h1>
            <h3 className="question-time">{question.time}</h3>
            <h2 className="question-text">{question.questiontxt}</h2>
          </div>
          <div className="response-area">
            <div className="input-field">
              <TextField
                margin="normal"
                sx={{ width: "300px" }}
                id="standard-basic"
                placeholder="Leave blank if you're not sure"
                onChange={change}
                label="Your idea"
                variant="standard"
              />
            </div>
            <button className="button-57" onClick={evaluateInput}>
              {" "}
              Submit
            </button>

            <div className="button-area"></div>
          </div>
        </div>
      );
    } else if (question.type === "startup") {
      questionArea = (
        <div>
          <div className="question">
            <h1 className="question-topic">{question.Topic}</h1>
            <h3 className="question-time">{question.time}</h3>
            <h2 className="question-text">{question.questiontxt}</h2>
          </div>
          <div className="response-area">
            <div className="input-field">
              <TextField
                margin="normal"
                sx={{ width: "300px" }}
                id="standard-basic"
                placeholder="Leave blank if you're not sure"
                onChange={change}
                label="Your idea"
                variant="standard"
              />
            </div>
            <button
              className="button-57"
              onClick={() => {
                setStartupName(input);
                //setScore("Startup", 0);
                window.localStorage.setItem("StartupName", input);
                evaluateInput();
              }}
            >
              Submit
            </button>

            <div className="button-area"></div>
          </div>
        </div>
      );
    } else if (question.type === "job") {
      questionArea = (
        <div>
          <div className="question">
            <h1 className="question-topic">{question.Topic}</h1>
            <h3 className="question-time">{question.time}</h3>
            <h2 className="question-text">{question.questiontxt}</h2>
          </div>
          <div className="response-area">
            <div className="button-area">
              <QuestionButton
                question={question}
                position={0}
                buttonText={question.btn1}
                hoverText={question.btn1hover}
                updateIndex={updateIndex}
                setResponse={setResponse}
                toResult={toResult}
                isJob={true}
                setJobName={setJobName}
                setScore={setScore}
              />
              <QuestionButton
                question={question}
                position={1}
                buttonText={question.btn2}
                hoverText={question.btn2hover}
                updateIndex={updateIndex}
                setResponse={setResponse}
                toResult={toResult}
                isJob={true}
                setJobName={setJobName}
                setScore={setScore}
              />
              <QuestionButton
                question={question}
                position={2}
                buttonText={question.btn3}
                hoverText={question.btn3hover}
                updateIndex={updateIndex}
                setResponse={setResponse}
                toResult={toResult}
                isJob={true}
                setJobName={setJobName}
                setScore={setScore}
              />
            </div>
          </div>
        </div>
      );
    } else
      questionArea = (
        <div>
          <div className="question">
            <h1 className="question-topic">{question.Topic}</h1>
            <h3 className="question-time">{question.time}</h3>
            <h2 className="question-text">{question.questiontxt}</h2>
          </div>
          <div className="response-area">
            <div className="button-area">
              <QuestionButton
                question={question}
                position={0}
                buttonText={question.btn1}
                hoverText={question.btn1hover}
                updateIndex={updateIndex}
                setResponse={setResponse}
                toResult={toResult}
                setJobName={setJobName}
                setScore={setScore}
              />
              <QuestionButton
                question={question}
                position={1}
                buttonText={question.btn2}
                hoverText={question.btn2hover}
                updateIndex={updateIndex}
                setResponse={setResponse}
                toResult={toResult}
                setJobName={setJobName}
                setScore={setScore}
              />
              {question.btn3 !== "" ? (
                <QuestionButton
                  question={question}
                  position={2}
                  buttonText={question.btn3}
                  hoverText={question.btn3hover}
                  updateIndex={updateIndex}
                  setResponse={setResponse}
                  toResult={toResult}
                  setJobName={setJobName}
                  setScore={setScore}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      );
  };

  renderQuestion();
  window.localStorage.setItem("time", question.time);

  return <>{questionArea}</>;
};

export default Question;
