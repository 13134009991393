import React, { useState } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const Login = ({ setScreen }) => {
  const db = firebase.firestore();
  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");

  const changeUserName = (event) => {
    event.preventDefault();
    setUserName(event.target.value);
  };

  const changeEmail = (event) => {
    event.preventDefault();
    setEmail(event.target.value);
  };

  const handleStatus = (event) => {
    event.preventDefault();
    setStatus(event.target.value);
  };

  const addUser = () => {
    db.collection("Users")
      .doc(username)
      .set({
        name: username,
        email: email,
        status: status,
      })
      .then(function () {
        console.log("name successfully written!");
      })
      .catch(function (error) {
        console.error("Error writing name: ", error);
      });
    window.localStorage.setItem("username", username);
  };

  return (
    <div className="login-page">
      <div className="welcome-area">
        <div className="welcome-text-container">
          <h2 className="welcome-text">
            Welcome! <br></br>Through this web app, you will experience the one
            year at PRIME. You can build your unique PRIME Profile by exploring
            all the wonderful resources PRIME and Brown University offers!
          </h2>
        </div>
      </div>
      <div className="input-area">
        <div className="input-body">
          <h2 className="email-field">Your Name</h2>
          <TextField
            margin="normal"
            sx={{ width: "300px", color: "white" }}
            id="standard-basic"
            placeholder=""
            onChange={changeUserName}
            label=""
            variant="standard"
          />

          <h2 className="email-field">Your Email</h2>
          <TextField
            margin="normal"
            sx={{ width: "300px" }}
            id="standard-basic"
            placeholder=""
            onChange={changeEmail}
            label=""
            variant="standard"
          />

          <div className="input-container">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={status}
                label="Status"
                onChange={handleStatus}
              >
                <MenuItem value={`I'm just exploring`}>
                  I'm just exploring
                </MenuItem>
                <MenuItem value={"Applicant"}>Applicant</MenuItem>
                <MenuItem value={"Admitted Student"}>Admitted Student</MenuItem>
                <MenuItem value={"Current Student"}>Current Student</MenuItem>
                <MenuItem value={"PRIME Alumnus"}>PRIME Alumnus</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <button
          className="button-59"
          onClick={() => {
            if (username !== "" && email !== "" && status !== "") {
              addUser();
              window.localStorage.setItem("pageState", 0);
              setScreen("Question");
            } else alert("Please fill out the relevant information.");
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default Login;
