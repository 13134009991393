import React, { useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  Legend,
  RadialLinearScale,
  Filler,
  Tooltip,
} from "chart.js";

import { Radar } from "react-chartjs-2";
import { CircularProgressWithLabel } from "@mui/material";

ChartJS.register(
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  RadialLinearScale,
  Filler
);

const Scoreboard = ({ getScore }) => {
  let startup_counter = getScore("Startup");
  let job_counter = getScore("Job");
  const [maxScale, setMaxScale] = useState(20);

  if (
    window.localStorage.getItem("Entrepreneurship") > maxScale ||
    window.localStorage.getItem("Creativity") > maxScale ||
    window.localStorage.getItem("Career") > maxScale ||
    window.localStorage.getItem("Social") > maxScale ||
    window.localStorage.getItem("Analytical") > maxScale
  ) {
    setMaxScale(maxScale * 1.2);
  }

  const options = {
    elements: {
      line: {
        borderWidth: 1,
        borderColor: "white",
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      r: {
        angleLines: {
          display: true,
          color: "#B7B09C",
        },
        grid: {
          display: true,
          color: "#B7B09C",
        },
        pointLabels: {
          color: "white",
          font: {
            size: 13,
          },
        },
        ticks: {
          display: false,
        },
        min: 0,
        max: maxScale,
      },
    },
  };

  const data = {
    labels: [
      `Entrepreneurship: ${getScore("Ent")}`,
      `Career: ${getScore("Car")}`,
      `Creativity: ${getScore("Cre")}`,
      `Analytical: ${getScore("Ana")}`,
      `Social: ${getScore("Soc")}`,
    ],
    datasets: [
      {
        label: "Score",
        data: [
          getScore("Ent"),
          getScore("Car"),
          getScore("Cre"),
          getScore("Ana"),
          getScore("Soc"),
        ],
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "white",
      },
    ],
  };

  return (
    <div className="scoreboard">
      <div className="score-container">
        <h4 className="score-title">Your PRIME Profile</h4>
        <div className="radar-graph">
          <Radar data={data} options={options} color="white"></Radar>
        </div>
        <div className="trackers">
          {startup_counter >= 0 ? (
            <>
              <h4 className="tracker-title">
                {window.localStorage.getItem("StartupName")}
              </h4>
              <div className="start-up-tracker">
                <CircularProgressbar
                  value={startup_counter}
                  minValue={0}
                  maxValue={4}
                  text={`${(startup_counter / 4) * 100}%`}
                  styles={buildStyles({
                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: "round",
                    // Text size
                    textSize: "12px",
                    // How long animation takes to go from one percentage to another, in seconds
                    pathTransitionDuration: 0.5,

                    // Can specify path transition in more detail, or remove it entirely
                    // pathTransition: 'none',

                    // Colors
                    pathColor: "#FFC72C",
                    textColor: "white",
                    trailColor: "grey",
                    backgroundColor: "#4E3629",
                  })}
                />

                <div className="start-up-to-do">
                  <p className="start-up-title">Tasks:</p>
                  {startup_counter >= 1 ? (
                    <p className="start-up-li-done">Bottom-up research</p>
                  ) : (
                    <p className="start-up-li">Bottom-up research</p>
                  )}
                  {startup_counter >= 2 ? (
                    <p className="start-up-li-done">Seek mentorship</p>
                  ) : (
                    <p className="start-up-li">Seek mentorship</p>
                  )}
                  {startup_counter >= 3 ? (
                    <p className="start-up-li-done">Perfect Elevator Pitch</p>
                  ) : (
                    <p className="start-up-li">Perfect Elevator Pitch</p>
                  )}
                  {startup_counter >= 4 ? (
                    <p className="start-up-li-done">Launch Prototype</p>
                  ) : (
                    <p className="start-up-li">Launch Prototype</p>
                  )}
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {job_counter >= 0 ? (
            <>
              <h4 className="tracker-title">
                {window.localStorage.getItem("JobName")}
              </h4>
              <div className="start-up-tracker">
                <CircularProgressbar
                  value={job_counter}
                  minValue={0}
                  maxValue={4}
                  text={`${(job_counter / 4) * 100}%`}
                  styles={buildStyles({
                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: "round",
                    // Text size
                    textSize: "12px",
                    // How long animation takes to go from one percentage to another, in seconds
                    pathTransitionDuration: 0.5,

                    // Can specify path transition in more detail, or remove it entirely
                    // pathTransition: 'none',

                    // Colors
                    pathColor: "#FFC72C",
                    textColor: "white",
                    trailColor: "grey",
                    backgroundColor: "#4E3629",
                  })}
                />

                <div className="start-up-to-do">
                  <p className="start-up-title">Tasks:</p>
                  {job_counter >= 1 ? (
                    <p className="start-up-li-done">Cover Letter Workshop</p>
                  ) : (
                    <p className="start-up-li">Cover Letter Workshop</p>
                  )}
                  {job_counter >= 2 ? (
                    <p className="start-up-li-done">Informational Interview</p>
                  ) : (
                    <p className="start-up-li">Informational Interview</p>
                  )}
                  {job_counter >= 3 ? (
                    <p className="start-up-li-done">Mock Interview</p>
                  ) : (
                    <p className="start-up-li">Mock Interview</p>
                  )}
                  {job_counter >= 4 ? (
                    <p className="start-up-li-done">Super Day Prep</p>
                  ) : (
                    <p className="start-up-li">Super Day Prep</p>
                  )}
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default Scoreboard;
