import React from "react";
import { useState } from "react";

const ActivityButton = ({ action, handleResponse }) => {
  const [hover, setHover] = useState("");
  return (
    <div className="activity-button">
      <button
        className="button-57"
        onMouseEnter={() => {
          setHover(action.hoverTxt);
        }}
        onMouseLeave={() => {
          setHover("");
        }}
        onClick={() => {
          handleResponse(action);
        }}
      >
        {action.text}
      </button>

      <p className="activity-hover-text">{hover}</p>
    </div>
  );
};

export default ActivityButton;
