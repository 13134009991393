import React from "react";
import Logo from "../Images/Brown_logo.png";
import "../App.css";

const Navbar = () => {
  return (
    <div className="topnav" id="myTopnav">
      <ul>
        <li>
          <img
            src={Logo}
            onClick={() => {
              window.location.reload();
            }}
            className="nav-logo"
            alt="Logo of Brown School of Engineering"
          />
        </li>
        <div className="navlink-container">
          <li>
            <a href="https://prime.brown.edu" target="_blank">
              PRIME WEBSITE
            </a>
          </li>
          <li>
            <a href="http://cab.brown.edu" target="_blank">
              COURSE CATALOG
            </a>
          </li>
        </div>
        <a href="javascript:void(0);" id="icon" className="icon">
          <i className="fa fa-bars fa-2x"></i>
        </a>
      </ul>
    </div>
  );
};

export default Navbar;
