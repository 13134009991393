import React from 'react'
import { useState } from 'react';
import ActivitiesHandler from './components/ActivitiesHandler';
import Home from './components/Home';
import QuestionHandler from './components/QuestionHandler';
import Login from './components/Login';
import "./App.css"
import ResultPage from './components/ResultPage';
const MainContent = ({getScore, setScore, unlock, check, setLocalStorage, setStartupName, setJobName, startupName, jobName}) => {
    const [screen, setScreen] = useState('Home');
    //0=quesiton, 1=explotre, 2=result
    const [pageState, setPageState] = useState(0);
    
    
    var currScreen;


    const evalScreen = () =>{
        switch(screen){
            case 'Home':
                currScreen = <Home setScreen={setScreen} pageState={pageState}/>
                break;
            case 'Login':
                currScreen = <Login setScreen={setScreen}/>
                break;
            case 'Question':
                currScreen = <QuestionHandler setScreen={setScreen} getScore={getScore} setScore={setScore} unlock={unlock} 
                setLocalStorage={setLocalStorage} setStartupName={setStartupName}
                setJobName={setJobName} setPageState = {setPageState}/>
                break;
            case 'Activities':
                currScreen = <ActivitiesHandler setScreen={setScreen} getScore={getScore} setScore={setScore} unlock={unlock} check = {check}
                setLocalStorage={setLocalStorage} setPageState = {setPageState} />
                break;
            case 'Result':
                currScreen = <ResultPage setScreen={setScreen} getScore={getScore} setPageState={setPageState} />
                break;
            default: return null;
    }
}
    evalScreen();
  return (
    <div className='main-body'>
        {currScreen}
        {/* <button onClick={()=>{setScreen('Home');window.localStorage.setItem('currIndex', 0);window.localStorage.clear();window.location.reload();
}}>Reset</button> */}
    </div>
  )
}
export default MainContent