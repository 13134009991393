import React from "react";
import { Button } from "@mui/material";

const ActivityResult = ({
  activity,
  response,
  setScreen,
  setScore,
  unlock,
  setLocalStorage,
  setPageState,
}) => {
  const updateResult = (action) => {
    if (action.effectType === "score") {
      setScore(action.effect, action.effectNum);
      if (action.effect === "Startup") setScore("Ent", 8);
      else if (action.effect === "Job") setScore("Car", 8);
    } else if (action.effectType === "unlock") {
      unlock(action.effect);
    } else if (action.effectType === "setCounter") {
      setLocalStorage(action.effect, action.effectNum);
    }
  };

  return (
    <div className="activities-home">
      <div className="result-area">
        <h2 className="result-text">{activity.actions[response].resultTxt}</h2>
        <div className="result-button">
          <button
            className="button-57"
            onClick={() => {
              window.localStorage.setItem("pageState", 0);
              setScreen("Question");
              updateResult(activity.actions[response]);
              activity.incrementCounter();
            }}
          >
            Got it!
          </button>
        </div>
      </div>
    </div>
  );
};

export default ActivityResult;
