import { useState, useEffect } from "react";
import Question from "./Question";
import QuestionResult from "./QuestionResult";
import NewQuestionList from "../Data/QuestionData.json";
import Scoreboard from "./Scoreboard";
import { Grid } from "@mui/material";

const QuestionHandler = ({
  setScreen,
  getScore,
  setScore,
  unlock,
  setLocalStorage,
  setTime,
  setStartupName,
  setJobName,
  setPageState,
}) => {
  const [state, setState] = useState("Question");
  const [currIndex, setCurrIndex] = useState(0);
  //use response to track the button clicked
  const [response, setResponse] = useState(0);
  const [nextIndex, setNextIndex] = useState(0);

  useEffect(() => {
    if (JSON.parse(window.localStorage.getItem("currIndex") === null)) {
      window.localStorage.setItem("currIndex", 0);
    } else {
      setCurrIndex(JSON.parse(window.localStorage.getItem("currIndex")));
      console.log(`currindex is ${currIndex}`);
    }
  }, []);

  /*
    We should consider switching from read file format to class construction
    
    class Question {
      constructor(key, type, text, responses, resourceUrl){
        this.key=key;
        this.type = type;
        this.text=text;
        this.responses=responses;
        this.resourceUrl=resourceUrl;
      }
    }
    */

  var currState;
  const evalState = () => {
    switch (state) {
      case "Question":
        currState = (
          <div>
            <Question
              question={NewQuestionList[currIndex]}
              setResponse={setResponse}
              setScore={setScore}
              setStartupName={setStartupName}
              setJobName={setJobName}
              toResult={() => {
                setState("Result");
              }}
              setTime={setTime}
            />
          </div>
        );
        break;
      case "Result":
        currState = (
          <div>
            <QuestionResult
              question={NewQuestionList[currIndex]}
              response={response}
              setScreen={setScreen}
              setScore={setScore}
              unlock={unlock}
              setLocalStorage={setLocalStorage}
              saveIndex={() => {
                setCurrIndex(
                  JSON.parse(window.localStorage.getItem("nextIndex"))
                );
              }}
              setPageState={setPageState}
            />
          </div>
        );
        break;
      default:
        return null;
    }
  };
  evalState();

  return (
    <div className="main-grid">
      <Scoreboard getScore={getScore} />
      <div className="question-body">{currState}</div>
    </div>
  );
};

export default QuestionHandler;
